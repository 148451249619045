import logo from './logo.svg';
import './App.css';
import { Box, Container, Button, Grid, TextField, Paper, Typography, ToggleButtonGroup, ToggleButton, CircularProgress, Alert, LinearProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SecurityIcon from '@mui/icons-material/Security';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import axios from 'axios';


function App() {

  const theme = createTheme({

    palette: {
      primary: {
        main: '#2d8aff'
      },
      secondary: {
        main: '#333'
      }



    },
  });

  const [alignment, setAlignment] = React.useState(true);
  const [code, setCode] = React.useState('');
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };




  const [emailAddress, setEmailAddress] = React.useState("hello");

  const [loading, setLoading] = React.useState(false);
  const [displayResults, setDisplayResults] = React.useState(false);
  const [genUsername, setGenUsername] = React.useState("hello");
  const [genDomain, setGenDomain ] = React.useState("mydomain.com.au");
  const [cookies, setCookie, removeCookie] = useCookies();
  const [existsWebServer, setExistsWebServer] = React.useState(false);
  const [existsEmailServer, setExistsEmailServer] = React.useState(false);
  const [mxConfigured, setMxConfigured] = React.useState(false);
  const [showDnsCheck, setShowDnsCheck] = React.useState(false);
  const [showDnsLoader, setShowDnsLoader] = React.useState(false);
  const disabledButton = loading || !emailAddress.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
  function handleGenerate(){




    setLoading(prev=>!prev)
    setDisplayResults(false)
    
    setTimeout( ()=> {
      const [username,domain] = emailAddress.split('@')
      setGenUsername(username)
      setGenDomain(domain)
      setLoading(prev=>!prev)
      setDisplayResults(prev=>!prev)
      handleCheckDNS(domain)

    }, 1000)
  }

useEffect(()=>{

  if(window.location.href == `http://103.119.109.109:3336/`){
    window.location.replace("https://mail-settings.dreamithost.com.au/");

  }

  const time = `${Date.now()}`
  const month = new Date().getDay()

 


  setCode(time.slice(0,(time.length/1.5)))
  setCookie('dns_check',   btoa(`${month}:${time}`),
  {
    path:'/',
    domain: '.dreamithost.com.au',
    sameSite:'none',
    secure: true,
  }

  


  
)

// issue is the axios post to the domain is lunar.dreamithost.com.au
setCookie('dns_check',   btoa(`${month}:${time}`),
{
  path:'/',
  domain: '.hosting-cloud.net',
  secure: true,
  sameSite:'none'
}





)


  
},[])



async function handleCheckDNS(domain){
  axios.defaults.withCredentials = true
  const data = {domain}
  const headers = { Authorization: `${btoa(`911380:`+code)}` }
  
  try {
    setShowDnsCheck(false)

    const exists = await axios.post(`/mv1/check/active/`, data,{headers})
    if(exists.data.active){
      setShowDnsLoader(exists.data.active)
      const result = await axios.post(`/mv1/check/domain/`, data,{headers})
      setShowDnsLoader(false)
     

   const{existsWebServer,existsEmailServer,mxConfigured} = result.data
   
   console.log(existsWebServer,existsEmailServer,mxConfigured)

   setExistsWebServer(existsWebServer)
   setExistsEmailServer(existsEmailServer)
   setMxConfigured(mxConfigured)
   setShowDnsCheck(exists.data.active)

 





   }


    }

   
  catch (error) {
    // if error dont show check
    setShowDnsCheck(false)
  
  }

 
}

async function handleEnter(e){

  if(e.code === 'Enter' && !disabledButton ){
    handleGenerate()
  }


}

  return (


    <ThemeProvider theme={theme}>
   <Container maxWidth='lg'>
  <Box  mt={'100px'} display={'flex'} justifyContent={'center'} alignItems={'center'} >

    <Box sx={{background:"white"}} padding={3} borderRadius={'5px'} border={'solid 1px rgb(234, 234, 234);'}  width={'600px'}>
   
    <Grid container spacing={1}>
    <Grid sm={12} item className='InputArea'>
    <Typography fontWeight={600} variant='h4' color={'primary'} >Mail Settings</Typography>
    <Typography fontWeight={400} color={'secondary'} mb={'40px'} variant='subtitle1' fontSize={'11px'}>Use the tool below to generate your email client mail settings, <br></br>enter in the email address you want your mail settings for.</Typography>
    </Grid>
 

    <Grid sm={6} item className='InputAreaName'><TextField onKeyDown={handleEnter} color={emailAddress.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) ? 'success':'error' } fullWidth disabled={loading}  onInput={(e)=>{
      
      setEmailAddress(e.target.value)

    }} placeholder='hello@mydomain.com.au' size='small' ></TextField></Grid>
    {/* <Grid sm={1} item className='InputArea'><TextField   disabled value={'@'} placeholder='hello' size='small' ></TextField></Grid> */}
    {/* <Grid item className='InputArea'><TextField disabled={loading}  onInput={(e)=>setDomain(e.target.value)} placeholder='mydomain.com.au' size='small' ></TextField></Grid> */}
    <Grid sm={1} item><Button  onClick={handleGenerate} disabled={disabledButton} disableElevation variant='contained' size='small' className='ButtonStyling' color='primary'>Generate</Button></Grid>

    <Grid sm={12}  item>
    {loading && <Box padding={10} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress></CircularProgress> </Box>}
    { displayResults && <Paper className='MailSettingsArea'>
      <ToggleButtonGroup
      className='ButtonGroup'
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value={true}> <SecurityIcon sx={{fontSize:'15px' ,mt:'-3px'}}/>SSL</ToggleButton>
      <ToggleButton value={false}> <RemoveModeratorIcon sx={{fontSize:'15px' ,mt:'-3px', mr: '1px'}} />  NO SSL</ToggleButton>

    </ToggleButtonGroup>
      {/* <Typography color={'grey'} textAlign={'center'} padding={4}  >No settings generated yet</Typography> */}

      {/* {alignment == 'ssl' &&  "ssl"}
      {alignment == 'nossl' &&  "no ssl"} */}

      <Box padding={2}>
      <Typography fontWeight={600} variant='h6' bgcolor={'#e8f2ff'} color={'#4aa2ff'} textAlign={'center'}  >Mail Client Manual Settings</Typography>
      <Typography fontWeight={400} color={'secondary'} mb={2} variant='subtitle1' fontSize={'11px'}>We recommend that you use IMAP and SMTP for your email account. </Typography>


        <Box display={'flex'}>
          {/* incomingServer */}

      <Grid sx={{mr:'5px', border: 'solid 1px #959595', padding: 1, borderRadius: '2px'}}  container>

      <Grid item sm={12} md={6} >
          <Grid item sm={12}> <Typography fontWeight={600}  bgcolor={'#e8f2ff'} color={'#4aa2ff'}  fontSize={'12px'}> Incoming Server Settings ({alignment ? 'SSL':'NO SSL'})</Typography> 
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}> <span>Username:</span> {genUsername}@{genDomain} </Typography>  </Grid>
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}><span> Password:</span> email address password  </Typography>  </Grid>
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}> <span>Server:</span> mail.{genDomain} </Typography>  </Grid>
          <Grid item sm={12} >
            <Box display={'flex'} gap={0.7}>
            <Typography fontWeight={500} color={'secondary'} fontSize={'10px'}><span>SSL/TLS:</span> ({alignment ? 'ON':'NONE/NO'})</Typography>
            <Typography fontWeight={500} color={'secondary'} fontSize={'10px'}><span>IMAP Port: </span>{alignment ? '993':'143'} </Typography>
            <Typography fontWeight={400} color={'secondary'} fontSize={'10px'}><span>POP Port:</span> {alignment ? '995':'110'} </Typography>
            </Box>  
          </Grid> 
          <Grid item sm={3} ></Grid> 
          <Grid item sm={2}> </Grid>
        
      </Grid>

      </Grid>


      <Grid item sm={12} md={6}>

          <Grid item sm={12}> <Typography  bgcolor={'#e8f2ff'} color={'#4aa2ff'}  fontWeight={600}  fontSize={'12px'}> Outgoing Server Settings ({alignment ? 'SSL':'NO SSL'}) </Typography> </Grid>
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}> <span>Username:</span> {genUsername}@{genDomain}</Typography>  </Grid>
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}><span> Password:</span> email address password  </Typography>  </Grid>
          <Grid item sm={12}><Typography fontWeight={400} color={'secondary'} fontSize={'12px'}> <span>Server:</span> mail.{genDomain} </Typography>  </Grid>
          <Grid item sm={12}>
          <Box display={'flex'} gap={0.7}>
          <Typography fontWeight={500} color={'secondary'} fontSize={'10px'}><span>SSL/TLS:</span> ({alignment ? 'ON':'NONE/NO'})</Typography>
          <Typography fontWeight={400} color={'secondary'} fontSize={'10px'}><span>SMTP Port:</span> {alignment ? '465':'587'} </Typography>
        
            </Box>  
          
          </Grid> 

      </Grid>

      </Grid>

      <div>{existsWebServer}</div>
{existsEmailServer}
{mxConfigured}

      </Box>

      {showDnsLoader && <LinearProgress sx={{mt: 1}} />}
       {showDnsCheck &&  <Alert sx={{mt: 1, fontSize: '10px'}} severity={
            
            mxConfigured &&  existsWebServer ? 'success':
            existsEmailServer  && mxConfigured ? 'success':
            mxConfigured && !existsEmailServer ? 'warning':
            mxConfigured && !existsWebServer ? 'warning':
            existsEmailServer && !mxConfigured ? 'warning':
            existsWebServer && !mxConfigured ? 'warning': 'error' } > 
            
            {mxConfigured &&  existsWebServer ?  `Your DNS looks to be configured correctly, both your MX and mail.${genDomain} DNS record propagate to the web server.`:
             existsEmailServer  && mxConfigured ? `Your DNS looks to be configured correctly, both your MX and mail.${genDomain} DNS record propagate to the Email Server.`:
             mxConfigured && !existsEmailServer ? `Your MX record appears to be configured correctly but your mail.${genDomain} record does not point to the email server.`:
             mxConfigured && !existsWebServer ? `Your MX record appears to be configured correctly but your mail.${genDomain} record does not point to the web server.`:
             existsEmailServer && !mxConfigured ? `Your mail.${genDomain} record is configured correctly, but your mx record points elsewhere, if this is intentional this may be due to an advanced setup, such as a proxy or 3rd party spam filter.`:
             existsWebServer && !mxConfigured ? `Your mail.${genDomain} record is configured correctly, but your mx record points elsewhere, if this is intentional this may be due to an advanced setup, such as a proxy or third party spam filter.`: 'Your domains DNS is not configured correctly, a proxy may be in use or your emails are hosted externally, if you have any issues please reach out to the team.'

             
             
             
              } </Alert>}  

     <Typography fontWeight={400} color={'secondary'} mt={2} variant='subtitle1' fontSize={'10px'}> <span>IMAP</span> Email messages synchronises between the server and your email mail application. Email messages that have been read/deleted/replied to will show up as such, both on the server and in the email application.</Typography>
     <Typography fontWeight={400} color={'secondary'} mt={2} variant='subtitle1' fontSize={'10px'}> <span>POP3</span> does not synchronise with the server. Email messages marked as read/deleted/replied to in the email application will not show up as such on the server. This means that future message downloads with POP3 will show all messages as unread on the server.</Typography>
     <Typography fontWeight={400} color={'secondary'} mt={2} variant='subtitle1' fontSize={'10px'}> Outgoing emails is sent using <span>SMTP</span>.</Typography>
      </Box>

      </Paper> }
  
      </Grid>
    
    </Grid>

    </Box>



  </Box>
   </Container>
  <br></br>
  <br></br>
  <br></br>
   </ThemeProvider>
  );
}

export default App;
